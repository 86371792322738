import React from 'react';
import logo from '../assets/icons/logo.png'
import { Link } from 'react-router-dom';
import cover1 from '../assets/images/cover1.webp'
import cover2 from '../assets/images/cover2.webp'
import cover3 from '../assets/images/cover3.webp'
import cover4 from '../assets/images/cover4.webp'



import step1 from '../assets/images/step1.png'
import step2 from '../assets/images/step2.png'

import hashnodeLogo from '../assets/images/hashnode-logo.png'
import devLogo from '../assets/images/dev-logo.png'

import WallOfLove from './walloflove';
const Home = () => {


    return (
        <div className="bg-gray-50">

            <div className="text-xl  flex border-b border-gray-100  p-2 md:w-10/12 mx-auto">
                <div className="flex items-center">
                    <img src={logo} alt="logo" className="w-8 h-8 mx-2" />
                    <h1 className="font-semibold md:text-xl text-lg font-Inter">学术Fun封面生成器</h1>
                </div>

                <a href="https://xueshu.fun" target="_blank" rel="noreferrer" className="hover:translate-x-2 duration-300 bg-gray-700 group rounded-xl md:px-4 text-white md:text-sm text-xs ml-auto mr-4 font-Inter font-semibold p-2">
                    <span className="text-sm">⭐ 前往学术Fun</span>
                </a>
            </div>

            <div className="  mx-auto md:px-20 py-6   flex flex-col items-center">
                <h1 className="md:w-7/12 m-10 text-center md:text-5xl text-3xl font-extrabold text-gray-700 font-Anek">
                    一键生成酷炫博客封面
                </h1>
            <Link to="/editor" className="hover:translate-x-2 duration-300 bg-gray-700 hover:bg-gray-800 group rounded-full px-4 md:px-8 text-white md:text-2xl text-lg mx-auto font-Inter font-semibold md:p-4 p-2">
                    <span className="md:text-2xl text-lg">开始生成 &rarr;</span>
                </Link>
            </div>




            <div className=" temple flex flex-row items-center justify-center mx-auto md:w-10/12">

                <div className="m-4 transform -translate-y-20 border animate hover:scale-105 hover:-rotate-3  rotate-6 duration-100 bg-white p-2 shadow-sm w-1/5 rounded-lg flex flex-col ">
                    <img src={cover2} className="border border-gray-100 rounded mb-2" alt="cover1" />
                    <p className="animate animate-pulse bg-gray-50 md:h-5 h-2 rounded mb-2"></p>
                    <p className="animate animate-pulse w-8/12 bg-gray-50 md:h-5 h-2 rounded mb-2"></p>

                </div>

                <div className="m-4 transform hover:scale-105 hover:rotate-3 -rotate-2  duration-300 bg-white p-4 shadow-sm w-1/3 rounded-lg flex flex-col ">
                    <img src={cover1} className="rounded border border-gray-100 mb-2" alt="cover-2" />
                    <p className="animate animate-pulse bg-gray-50 md:h-6 h-3 rounded mb-2"></p>
                    <p className="animate animate-pulse w-8/12 bg-gray-50 md:h-6 h-3 rounded mb-2"></p>

                </div>

                <div className="m-4 transform -translate-y-20 border animate hover:scale-105 hover:rotate-3 -rotate-6 duration-100 bg-white p-2 shadow-sm w-1/5 rounded-lg flex flex-col ">
                    <img src={cover3} className="rounded border border-gray-100 mb-2" alt="cover3" />
                    <p className="animate animate-pulse bg-gray-50 md:h-5 h-2 rounded mb-2"></p>
                    <p className="animate animate-pulse w-8/12 bg-gray-50 md:odd:h-5 h-2 rounded mb-2"></p>

                </div>

            </div>





            <div className="md:my-32  my-10 mx-auto">

                <h2 className="md:text-5xl text-3xl md:w-1/2 mx-auto mt-32 font-bold font-Anek text-center text-gray-700">三步生成，使用 <span className="text-indigo-400">教程</span>。</h2>
                <div className="md:w-8/12 my-20 flex md:flex-row flex-col mx-auto">

                    <div className="md:w-1/3 flex flex-col mx-10">

                        <div className="text-center">
                            <div className="text-xl mx-auto w-10 h-10 p-2 font-bold text-white bg-gray-700 rounded-full flex items-center justify-center">1</div>

                            <p className="text-2xl my-2 font-semibold font-Inter text-gray-600">填写文章标题</p>
                        </div>
                        <img src={step1} alt="preview" className="mt-2 rounded-lg shadow-sm" />

                    </div>

                    <div className="flex items-center font-bold text-3xl text-center mx-auto">--------&rarr;</div>

                    <div className="flex flex-col md:w-1/3 mx-10">

                        <div className="text-center">
                            <div className="text-xl mx-auto w-10 h-10 p-2 font-bold text-white bg-gray-700 rounded-full flex items-center justify-center">2</div>

                            <p className="text-2xl my-2 font-semibold font-Inter text-gray-600">自定义颜色、字体、图标</p>
                        </div>
                        <img src={step2} alt="preview" className="mt-2 rounded-lg shadow-sm" />

                    </div>
                </div>



                <div className="md:w-8/12  flex md:flex-row flex-col justify-center items-center mx-auto">

                    <div className="text-center md:w-1/3 m-4">
                        <div className="text-xl mx-auto w-10 h-10 p-2 font-bold text-white bg-gray-700 rounded-full flex items-center justify-center">3</div>

                        <p className="text-2xl my-2 font-semibold font-Inter text-gray-600">选择不同主题</p>
                    </div>

                    <div className="flex md:w-8/12 hideout p-6">
                        <div className="flex flex-col w-1/2 ">
                            <img src={cover1} alt="preview" className=" hover:scale-105 duration-300 m-2 rounded-lg shadow-sm" />
                            <img src={cover2} alt="preview" className="hover:scale-105 duration-300  m-2 rounded-lg shadow-sm" />

                        </div>


                        <div className="flex flex-col mt-4 w-1/2">
                            <img src={cover3} alt="preview" className="hover:scale-105 duration-300   m-2 rounded-lg shadow-sm" />
                            <img src={cover4} alt="preview" className="hover:scale-105 duration-300  m-2 rounded-lg shadow-sm" />

                        </div>

                    </div>

                </div>

                <div className="md:w-8/12 mx-auto pt-24 p-4">
                       <Link to="/editor" >
                        <button className="flex mx-auto my-4 hover:translate-x-2 duration-300 bg-gray-700  rounded-full px-6 text-white text-xl font-Inter font-semibold p-4">开始生成 &rarr;</button>
                    </Link>
                </div>

            </div>




            <footer className="bg-white p-10 flex md:flex-row flex-col font-Inter md:px-20 justify-center items-center">
                <div className="md:w-1/2 flex flex-col">
                    <span className="text-lg">学术Fun💛出品 <a href="https://xueshu.fun" className="font-semibold" target="_blank" rel="noreferrer">https://xueshu.fun</a></span>
                    <span className="text-lg font-Nunito">查看<a href="https://xueshu.fun" target="_blank" rel="noreferrer" className="text-purple-400 hover:text-pink-400">更多</a>优质视频课程、课程资料和科研软件工具等全方位的学习资源</span>
                </div>

                <div className="md:text-lg text-sm flex flex-wrap  ">
                    <a href="https://xueshu.fun" target="_blank" rel="noreferrer" className="m-2 hover:font-semibold">⭐ 前往学术Fun</a>
                </div>
            </footer>

        </div>
    );
}

export default Home;